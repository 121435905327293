<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0"></h6>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="card mb-4">
        <div class="card-header mt--4">
          <div class="row">
            <div class="col">
              <h3 class="mb-0 text-center">{{ tt("mcr_detail") }}</h3>
            </div>
          </div>
        </div>
        <div class="card-body">
          
        </div>
        <div class="card-footer pb-0 ">                           
            <span class="float-right mt--4 mb-4">
              Total Items {{total_mcr_item}}
            </span>
        </div>
      </div>
      <div class="row">
        <div v-if="formtype == 'edit'" class="col text-center">
            <router-link :to="'/material/draft-list'" class="btn btn-sm btn-dark">Draft List</router-link>  
            <base-button size="sm" type="button" class="btn btn-lg btn-login" style="background: linear-gradient(to left,  #2e41db, #5626c7);padding-right: 40px;padding-left: 40px;color: #fff;outline-color: black;" v-on:click="sendMcr(mcr_code)" :disabled="btnSend.onLoading">
              <span v-if="btnSend.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
              <span v-else>{{ tt('send') }}</span>
            </base-button>
        </div>
      </div>
    </div>

    <!-- MODAL TRACKING  -->
    <tracking v-if="formTracking.show" form-show="formTracking.show" :mcr-code="mcr_code"/>

    <!--Form Edit Subject-->
    <validation-observer v-slot="{ invalid }">
      <modal :show.sync="formMcrInformation.show">
        <template slot="header">
          <h5 class="modal-title">{{ tt("edit_subject") }}</h5>
        </template>
        <div>
          <label class="form-control-label"
            >{{ tt("subject") }} <span class="text-danger">*</span></label
          >
          <base-input
            :name="tt('subject')"
            :placeholder="tt('subject')"
            v-model="mcrInformationEdit.new_subject"
            rules="required"
          ></base-input>
        </div>
        <template slot="footer">
          <base-button
            type="secondary"
            @click="formMcrInformation.show = false"
            >{{ tt("close") }}</base-button
          >
          <base-button
            type="primary"
            v-on:click="updateMcrInformation"
            :disabled="btnUpdate.onLoading || invalid"
          >
            <span v-if="btnUpdate.onLoading"
              ><i class="fas fa-spinner fa-spin"></i>
              {{ tt("please_wait") }}</span
            >
            <span v-else>
              {{ tt("update") }}
            </span>
          </base-button>
        </template>
      </modal>
    </validation-observer>
    <!--Form Edit Subject-->

    <!--Form A1-->
    <modal :show.sync="form.a1.show" size="lg">
      <template slot="header">
        <h5 class="modal-title">
          <span v-if="form.a1.type == 'add'">{{ tt("add_new") }}</span>
          <span v-else-if="form.a1.type == 'edit'">{{ tt("edit") }}</span>
          <span v-else>{{ tt("detail") }}</span>
        </h5>
      </template>

      <div id="accordionBdsA1">
        <div class="card z-depth-0 bordered">
          <div class="card-header bg-secondary border" id="headingOneBdsA1">
            <div class="row">
              <div class="col-md-6">
                <h5 class="mb-0">
                  <a
                    href="javascript:void(0)"
                    @click="toggleAccordion(accordion.a1.bds.show, 'bdsA1')"
                    >{{ tt("basic_data_&_specification") }}
                    <span class="text-danger">*</span></a
                  >
                </h5>
              </div>
            </div>
          </div>
          <div id="collapseBdsA1" :class="'collapse ' + accordion.a1.bds.show">
            <div class="card-body border">
              <div class="row">
                <div class="col-md-6">
                  <label class="form-control-label"
                    >{{ tt("material_type") }}
                    <span class="text-danger">*</span></label
                  >
                  <base-input :name="tt('material_type')" rules="required">
                    <el-select
                      :disabled="form.a1.disabled"
                      class="select-danger"
                      v-model="inputDraftListA1.mtype_code"
                      :placeholder="tt('choose_material_type')"
                    >
                      <el-option
                        class="select-danger"
                        :value="mt.code"
                        :label="mt.code + ' - ' + mt.description"
                        :key="mt.code"
                        v-for="mt in materialType"
                      ></el-option>
                    </el-select>
                  </base-input>
                </div>
                <div class="col-md-6">
                  <label class="form-control-label"
                    >{{ tt("item_type") }}
                    <span class="text-danger">*</span></label
                  >
                  <base-input :name="tt('item_type')" rules="required">
                    <el-select
                      :disabled="form.a1.disabled"
                      class="select-danger"
                      v-model="inputDraftListA1.item_type_code"
                      :placeholder="tt('choose_item_type')"
                    >
                      <el-option
                        class="select-danger"
                        :value="it"
                        :label="it"
                        :key="it"
                        v-for="it in itemType"
                      ></el-option>
                    </el-select>
                  </base-input>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <label class="form-control-label"
                    >{{ tt("plant") }} <span class="text-danger">*</span></label
                  >
                  <base-input :name="tt('plant')" rules="required">
                    <el-autocomplete
                      :disabled="form.a1.disabled"
                      v-model="inputDraftListA1.plant_code"
                      :fetch-suggestions="querySearchPlant"
                      :placeholder="tt('choose_plant')"
                      :trigger-on-focus="false"
                      @select="selectPlant"
                      size="large"
                      style="width: 100%"
                    ></el-autocomplete>
                  </base-input>
                </div>
                <div class="col-md-6">
                  <label class="form-control-label"
                    >{{ tt("fabric_or_non_fabrication") }}
                    <span class="text-danger">*</span></label
                  >
                  <base-input
                    :name="tt('fabric_or_non_fabrication')"
                    rules="required"
                  >
                    <el-select
                      :disabled="form.a1.disabled"
                      class="select-danger"
                      v-model="inputDraftListA1.fab_non_fab"
                      :placeholder="tt('choose_fabric_or_non_fabrication')"
                    >
                      <el-option
                        class="select-danger"
                        :value="fnf"
                        :label="fnf"
                        :key="fnf"
                        v-for="fnf in fabNonFab"
                      ></el-option>
                    </el-select>
                  </base-input>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <label class="form-control-label"
                    >{{ tt("uom") }} <span class="text-danger">*</span></label
                  >
                  <base-input :name="tt('uom')" rules="required">
                    <el-autocomplete
                      :disabled="form.a1.disabled"
                      v-model="inputDraftListA1.uom"
                      :fetch-suggestions="querySearchUom"
                      :placeholder="tt('choose_unit_of_measure')"
                      :trigger-on-focus="false"
                      @select="selectUom"
                      size="large"
                      style="width: 100%"
                    ></el-autocomplete>
                  </base-input>
                </div>
                <div class="col-md-6">
                  <label class="form-control-label"
                    >{{ tt("bom_or_non_bom") }}
                    <span class="text-danger">*</span></label
                  >
                  <base-input :name="tt('bom_or_non_bom')" rules="required">
                    <el-select
                      :disabled="form.a1.disabled"
                      class="select-danger"
                      v-model="inputDraftListA1.bom_non_bom"
                      :placeholder="tt('choose_bom_or_non_bom')"
                    >
                      <el-option
                        class="select-danger"
                        :value="bnb"
                        :label="bnb"
                        :key="bnb"
                        v-for="bnb in bomNonBom"
                      ></el-option>
                    </el-select>
                  </base-input>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <label class="form-control-label"
                    >{{ tt("critical_non_critical") }}
                    <span class="text-danger">*</span></label
                  >
                  <base-input
                    :name="tt('critical_non_critical')"
                    rules="required"
                  >
                    <el-select
                      :disabled="form.a1.disabled"
                      class="select-danger"
                      v-model="inputDraftListA1.critical_non_critical"
                      :placeholder="tt('choose_critical_non_critical')"
                    >
                      <el-option class="select-danger" value="Critical"
                        >Critical</el-option
                      >
                      <el-option class="select-danger" value="Non Critical"
                        >Non Critical</el-option
                      >
                    </el-select>
                  </base-input>
                </div>
              </div>
              <label class="form-control-label"
                >{{ tt("item_name") }} <span class="text-danger">*</span></label
              >
              <base-input
                :disabled="form.a1.disabled"
                :name="tt('item_name')"
                :placeholder="tt('item_name')"
                v-model="inputDraftListA1.item_name"
                rules="required"
              ></base-input>

              <label class="form-control-label mt-4"
                >{{ tt("specification") }}
                <span class="text-danger">*</span></label
              >
              <base-input :name="tt('specification')" rules="required">
                <textarea
                  :disabled="form.a1.disabled"
                  class="form-control"
                  rows="5"
                  v-model="inputDraftListA1.specification"
                ></textarea>
              </base-input>
            </div>
          </div>
        </div>
      </div>

      <div id="accordionMpnA1">
        <div class="card z-depth-0 bordered">
          <div class="card-header bg-secondary border" id="headingOneMpnA1">
            <div class="row">
              <div class="col-md-6">
                <h5 class="mb-0">
                  <a
                    href="javascript:void(0)"
                    @click="toggleAccordion(accordion.a1.mpn.show, 'mpnA1')"
                    >{{ tt("manufacturer_part_number") }}</a
                  >
                </h5>
              </div>
              <div class="col-md-6 text-right">
                <base-button
                  v-if="!form.a1.disabled"
                  size="sm"
                  type="default"
                  @click="addMpnA1">
                  {{ tt("add_new") }}
                  </base-button>
              </div>
            </div>
          </div>
          <div id="collapseMpnA1" :class="'collapse ' + accordion.a1.mpn.show">
            <div class="card-body border">
              <table
                class="table align-items-center table-flush table-bordered"
              >
                <thead class="card-header bg-primary">
                  <tr>
                    <th width="500" class="text-white">
                      {{ tt("manufacturer_code") }}
                    </th>
                    <th width="150" class="text-white">{{ tt("mpn") }}</th>
                    <th width="150" class="text-white">{{ tt("type") }}</th>
                    <th class="text-white">{{ tt("note") }}</th>
                    <th width="1"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(mpn, key) in manufacturePartNumberA1.manufacture_code"
                    v-bind:key="mpn.manufacture_code"
                  >
                    <td>
                      <el-autocomplete
                        :disabled="form.a1.disabled"
                        v-model="manufacturePartNumberA1.manufacture_code[key]"
                        :fetch-suggestions="querySearchManufacture"
                        :placeholder="tt('choose_manufacture')"
                        :trigger-on-focus="false"
                        @focus="selectedManufactureKey(key)"
                        @select="selectManufacture"
                        size="small"
                        style="width: 100%"
                      ></el-autocomplete>
                    </td>
                    <td>
                      <input
                        class="form-control form-control-sm"
                        :name="tt('mpn')"
                        :placeholder="tt('mpn')"
                        v-model="manufacturePartNumberA1.mpn[key]"
                        rules="required"
                      />
                    </td>
                    <td>
                      <select
                        class="form-control form-control-sm"
                        v-model="manufacturePartNumberA1.manufacture_type[key]"
                      >
                        <option value="">Choose Type</option>
                        <option
                          :value="mt.code"
                          v-for="mt in manrefType"
                          v-bind:key="mt.id"
                          >{{ mt.description }}</option
                        >
                      </select>
                    </td>
                    <td>
                      <input
                        class="form-control form-control-sm"
                        :name="tt('note')"
                        :placeholder="tt('note')"
                        v-model="manufacturePartNumberA1.manufacture_note[key]"
                        rules="required"
                      />
                    </td>
                    <td>
                      <i
                        v-if="!form.a1.disabled"
                        class="fa fa-trash text-danger"
                        @click="removeMpnA1(key)"
                      ></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div id="accordionEqiA1">
        <div class="card z-depth-0 bordered">
          <div class="card-header bg-secondary border" id="headingOneEqiA1">
            <div class="row">
              <div class="col-md-6">
                <h5>
                  <a
                    href="javascript:void(0)"
                    @click="toggleAccordion(accordion.a1.eqi.show, 'eqiA1')"
                    >{{ tt("equipment_&_quantity_install") }}</a
                  >
                </h5>
              </div>
              <div class="col-md-6 text-right">
                <base-button
                  v-if="!form.a1.disabled"
                  size="sm"
                  type="default"
                  @click="addEqiA1"
                  >{{ tt("add_new") }}</base-button
                >
              </div>
            </div>
          </div>
          <div id="collapseEqiA1" :class="'collapse ' + accordion.a1.eqi.show">
            <div class="card-body border">
              <table
                class="table align-items-center table-flush table-bordered"
              >
                <thead class="card-header bg-primary">
                  <tr>
                    <th width="500"class="text-white">{{ tt("equipment_code") }}</th>
                    <th width="200" class="text-white">
                      {{ tt("quantity_install") }}
                    </th>
                    <th width="200" class="text-white">
                      {{ tt("drawing_number") }}
                    </th>
                    <th width="1"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(eqi,
                    key) in equipmentQuantityInstallA1.equipment_code"
                    v-bind:key="eqi.equipment_code"
                  >
                    <td>
                      <el-autocomplete
                        :disabled="form.a1.disabled"
                        v-model="equipmentQuantityInstallA1.equipment_code[key]"
                        :fetch-suggestions="querySearchEquipment"
                        :placeholder="tt('choose_equipment')"
                        :trigger-on-focus="false"
                        @focus="selectedEquipmentKey(key)"
                        @select="selectEquipment"
                        size="small"
                        style="width: 100%"
                      ></el-autocomplete>
                    </td>
                    <td>
                      <input
                        class="form-control form-control-sm"
                        :name="tt('qty_install')"
                        :placeholder="tt('qty_install')"
                        v-model="equipmentQuantityInstallA1.qty_installed[key]"
                        rules="required"
                      />
                    </td>
                    <td>
                      <select
                        class="form-control form-control-sm"
                        v-model="equipmentQuantityInstallA1.drawing_number[key]"
                      >
                        <option value="">Choose Type</option>
                        <option
                          :value="dm.drawing_number"
                          v-for="dm in drawingMaster"
                          v-bind:key="dm.drawing_number"
                          >{{ dm.drawing_name }}</option
                        >
                      </select>
                    </td>
                    <td>
                      <i
                        v-if="!form.a1.disabled"
                        class="fa fa-trash text-danger"
                        @click="removeEqiA1(key)"
                      ></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div id="accordionAdA1">
        <div class="card z-depth-0 bordered">
          <div class="card-header bg-secondary border" id="headingOneAdA1">
            <div class="row">
              <div class="col-md-6">
                <h5>
                  <a
                    href="javascript:void(0)"
                    @click="toggleAccordion(accordion.a1.ad.show, 'adA1')"
                    >{{ tt("attachment_document") }}
                    <span class="text-danger">*</span></a
                  >
                </h5>
              </div>
              <div class="col-md-6 text-right">
                <base-button
                  v-if="!form.a1.disabled"
                  size="sm"
                  type="default"
                  @click="addAdA1"
                  >{{ tt("add_new") }}</base-button
                >
              </div>
            </div>
          </div>
          <div id="collapseAdA1" :class="'collapse ' + accordion.a1.ad.show">
            <div class="card-body border">
              <div class="table-responsive">
                <table
                  class="table align-items-center table-flush table-bordered"
                >
                  <thead class="card-header bg-primary">
                    <tr>
                      <th class="text-white">{{ tt("file_name") }}</th>
                      <th width="1"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(ad,
                      key) in attachmentDocumentA1.file_attachment_name"
                      v-bind:key="ad.file_name"
                    >
                      <td>
                        <file-input
                          :disabled="form.a1.disabled"
                          @change="changeFileAttachmentNameA1"
                          :initialLabel="ad.file_attachment_name"
                          :ref="'file' + key"
                        ></file-input>
                      </td>
                      <td>
                        <a
                          href="javascript:void(0)"
                          v-if="!form.a1.disabled"
                          @click="removeAdA1(key)"
                          ><i class="fa fa-trash text-danger"></i
                        ></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <label class="form-control-label mt-4"
        >{{ tt("note") }} <span class="text-danger">*</span></label
      >
      <base-input :name="tt('note')" rules="required">
        <textarea
          :disabled="form.a1.disabled"
          class="form-control"
          rows="5"
          v-model="inputDraftListA1.note"
        ></textarea>
      </base-input>
      <template slot="footer">
        <base-button type="secondary" @click="form.a1.show = false">{{
          tt("close")
        }}</base-button>
        <base-button
          type="primary"
          @click="saveA1('add')"
          v-if="form.a1.type == 'add'"
          :disabled="form.a1.btnSave.onLoading"
          >{{ tt("add") }}</base-button
        >
        <base-button
          type="primary"
          @click="saveA1('update')"
          v-else-if="form.a1.type == 'edit'"
          :disabled="form.a1.btnSave.onLoading"
          >{{ tt("edit") }}</base-button
        >
      </template>
    </modal>
    <!--Form A1-->
  </div>
</template>
<script>
import { mapState } from "vuex";
import Api from "@/helpers/api";
import draftList from "@/services/material/draftList.service";
import materialType from "@/services/master/materialType.service";
import plantCode from "@/services/master/plantCode.service";
import unitOfMeasure from "@/services/dictionary/unitOfMeasure.service";
import manufacture from "@/services/master/manufacture.service";
import manrefType from "@/services/master/manrefType.service";
import equipmentCode from "@/services/master/equipmentCode.service";
import drawingMaster from "@/services/dictionary/drawingMaster.service";

export default {
  data() {
    return {
      formTracking: {
          show: false
      },  
      loadTimeout: null,
      btnUpdate: {
        onLoading: false
      },
      btnSend: {
        onLoading: false
      },
      formMcrInformation: {
        show: false
      },
      form: {
        a1: {
          show: false,
          disabled: false,
          type: "add",
          btnSave: {
            onLoading: false
          }
        }
      },
      mcrType: "a1",
      inputDraftList: {},
      inputDraftListA1: {
        mtype_code: "",
        item_type_code: "",
        plant_code: "",
        fab_non_fab: "",
        uom: "",
        bom_non_bom: "",
        item_name: "",
        specification: "",
        critical_non_critical: "",
        note: ""
      },

      draftList: {},
      mcrInformation: {},
      mcrInformationEdit: {
        new_subject: ""
      },
      parameter: {},
      category: {},
      mcrItem: [],
      materialType: {},
      plantCode: [],
      unitOfMeasure: [],
      manufacture: [],
      manrefType: {},
      equipmentCode: [],
      abcIndicator: {},
      valuationClass: {},
      valuationCategory: {},
      valuationType: {},
      priceControl: {},
      distributionChannel: {},
      division: {},
      materialGroup: {},
      itemType: {},
      fabNonFab: {},
      bomNonBom: {},
      drawingMaster: {},
      manufactureKey: '',
      equipmentKey: '',
      manufacturePartNumberA1: {
        manufacture_code: [],
        mpn: [],
        manufacture_type: [],
        manufacture_note: []
      },
      equipmentQuantityInstall: {
        equipment_code: [],
        qty_installed: [],
        drawing_number: []
      },
      equipmentQuantityInstallA1: {
        equipment_code: [],
        qty_installed: [],
        drawing_number: []
      },
      total_mcr_item: '',
      attachmentDocument: {
        file_name: [],
        file_type: []
      },
      attachmentDocumentA1: {
        file_attachment_name: []
      },
      accordion: {
        a1: {
          bds: {
            show: "show"
          },
          mpn: {
            show: ""
          },
          eqi: {
            show: ""
          },
          ad: {
            show: ""
          }
        }
      },
      mcr_code: window.location.hash.split("/")[3],
      token: window.location.hash.split("/")[4],
      formtype: window.location.hash.split("/")[5]
    };
  },
  computed: {
    ...mapState({
      authUserPermission: state => state.auth.userPermission
    })
  },
  mounted() {
    this.get();
    this.getMaterialType();
    this.getPlantCode();
    this.getUnitOfMeasure();
    this.getManufacture();
    this.getManrefType();
    this.getEquipmentCode();
    this.getDrawingMaster();
  },
  methods: {
    get() {
      let context = this;
      Api(context, draftList.getMcrItem(context.mcr_code, context.token))
        .onSuccess(function(response) {
          context.mcrItem = response.data.data.mcr_item;
          context.mcrInformation = response.data.data.mcr[0];
          context.parameter = response.data.data.parameter;
          context.category = response.data.data.category;
          context.itemType = response.data.data.item_type;
          context.fabNonFab = response.data.data.fab_non_fab;
          context.bomNonBom = response.data.data.bom_non_bom;
          context.mcrInformationEdit.new_subject =
            response.data.data.mcr[0].subject;
          context.total_mcr_item = response.data.data.mcr_item.length;
        })
        .call();
    },
    getMaterialType() {
      let context = this;
      Api(context, materialType.get(null, { per_page: "none" }))
        .onSuccess(function(response) {
          context.materialType = response.data.data.data.data;
        })
        .onError(function(error) {
          if (error.response.status == 404) {
            context.materialType = [];
          }
        })
        .call();
    },
    getPlantCode() {
      let context = this;
      Api(context, plantCode.get(null, { per_page: "none" }))
        .onSuccess(function(response) {
          response.data.data.data.data.forEach(function(item, index) {
              context.plantCode[index] = {
                  'code': item['plant_code'],
                  'value': item['plant_code'] +' - '+ item['description']
              };
          });  
        })
        .onError(function(error) {
          if (error.response.status == 404) {
            context.plantCode = [];
          }
        })
        .call();
    },
    getUnitOfMeasure() {
      let context = this;
      Api(context, unitOfMeasure.get(null, { per_page: "none" }))
        .onSuccess(function(response) {
          response.data.data.data.data.forEach(function(item, index) {
              context.unitOfMeasure[index] = {
                  'code': item['code'],
                  'value': item['code'] +' - '+ item['description']
              };
          });
        })
        .onError(function(error) {
          if (error.response.status == 404) {
            context.unitOfMeasure = [];
          }
        })
        .call();
    },
    getManufacture() {
      let context = this;
      Api(context, manufacture.get(null, { per_page: "none" }))
        .onSuccess(function(response) {
          response.data.data.data.data.forEach(function(item, index) {
              context.manufacture[index] = {
                  'code': item['manufacture_code'],
                  'value': item['manufacture_code'] +' - '+ item['manufacture_name']
              };
          });
        })
        .onError(function(error) {
          if (error.response.status == 404) {
            context.manufacture = [];
          }
        })
        .call();
    },
    getManrefType() {
      let context = this;
      Api(context, manrefType.get(null, { per_page: "none" }))
        .onSuccess(function(response) {
          context.manrefType = response.data.data.data.data;
        })
        .onError(function(error) {
          if (error.response.status == 404) {
            context.manrefType = [];
          }
        })
        .call();
    },
    getEquipmentCode() {
      let context = this;
      Api(context, equipmentCode.get(null, { per_page: "none" }))
        .onSuccess(function(response) {
          response.data.data.data.data.forEach(function(item, index) {
              context.equipmentCode[index] = {
                  'code': item['equipment_code'],
                  'value': item['equipment_code'] +' - '+ item['equipment_name']
              };
          });
        })
        .onError(function(error) {
          if (error.response.status == 404) {
            context.equipmentCode = [];
          }
        })
        .call();
    },

    getDrawingMaster() {
      let context = this;
      Api(context, drawingMaster.get(null, { per_page: "none" }))
        .onSuccess(function(response) {
          context.drawingMaster = response.data.data.data.data;
        })
        .onError(function(error) {
          if (error.response.status == 404) {
            context.drawingMaster = [];
          }
        })
        .call();
    },

    handleTableAction(command) {
      this.formTracking.show = false;
      switch (command.action) {
        case "detail":
          this.detail(command.data);
          break;
        case "remove":
          this.remove(command.data);
          break;
        default:
          break;
      }
    },
    detail(data) {
      let context = this;
      if (data.btn == "detail") {
        context.form.a1.show = true;
        context.form.a1.disabled = true;
        context.form.a1.type = "detail";
      } else {
        context.form.a1.show = true;
        context.form.a1.disabled = false;
        context.form.a1.type = "edit";
      }

      Api(context, draftList.getDetailMcrItem(data.mcr_item_code))
        .onSuccess(function(response) {
          context.inputDraftListA1 = response.data.data[0];
          context.inputDraftListA1.uom = response.data.data[0].uom_code;
        })
        .onError(function(error) {
          if (error.response.status == 404) {
            context.inputDraftListA1 = [];
          }
        })
        .call();

      Api(context, draftList.getMcrItemMpn(data.mcr_item_code))
        .onSuccess(function(response) {
          for (var i = 0; i < response.data.data.length; i++) {
            context.manufacturePartNumberA1.manufacture_code[i] = response.data.data[i].manufacture_code;
            context.manufacturePartNumberA1.mpn[i] = response.data.data[i].mpn;
            context.manufacturePartNumberA1.manufacture_type[i] = response.data.data[i].manufacture_type;
            context.manufacturePartNumberA1.manufacture_note[i] = response.data.data[i].manufacture_note;
          }
        })
        .onError(function(error) {
          if (error.response.status == 404) {
            context.manufacturePartNumberA1.manufacture_code = [];
            context.manufacturePartNumberA1.mpn = [];
            context.manufacturePartNumberA1.manufacture_type = [];
            context.manufacturePartNumberA1.manufacture_note = [];
          }
        })
        .call();

      Api(context, draftList.getMcrItemEquipment(data.mcr_item_code))
        .onSuccess(function(response) {
          for (var i = 0; i < response.data.data.length; i++) {
            context.equipmentQuantityInstallA1.equipment_code[i] =
              response.data.data[i].equipment_code;
            context.equipmentQuantityInstallA1.qty_installed[i] =
              response.data.data[i].qty_installed;
            context.equipmentQuantityInstallA1.drawing_number[i] =
              response.data.data[i].drawing_number;
          }
        })
        .onError(function(error) {
          if (error.response.status == 404) {
            context.equipmentQuantityInstallA1.equipment_code = [];
            context.equipmentQuantityInstallA1.qty_installed = [];
            context.equipmentQuantityInstallA1.drawing_number = [];
          }
        })
        .call();

      Api(context, draftList.getMcrItemAttachment(data.mcr_item_code))
        .onSuccess(function(response) {
          context.attachmentDocumentA1.file_attachment_name =
            response.data.data;
        })
        .onError(function(error) {
          if (error.response.status == 404) {
            context.attachmentDocumentA1.file_attachment_name = [];
          }
        })
        .call();
    },
    remove(data) {
      let context = this;
      context.confirmDialog(context.tt("confirm_delete")).then(result => {
        if (result.value) {
          Api(context, draftList.deleteMcrItem(data.id))
            .onSuccess(function(response) {
              context.$notify({
                message: response.data.message,
                type: "success"
              });
              context.get();
            })
            .call();
        }
      });
    },
    create(key) {
      this.inputDraftListA1.mtype_code = "";
      this.inputDraftListA1.item_type_code = "";
      this.inputDraftListA1.plant_code = "";
      this.inputDraftListA1.fab_non_fab = "";
      this.inputDraftListA1.uom = "";
      this.inputDraftListA1.bom_non_bom = "";
      this.inputDraftListA1.item_name = "";
      this.inputDraftListA1.specification = "";
      this.inputDraftListA1.note = "";

      this.manufacturePartNumberA1.manufacture_code = [];
      this.manufacturePartNumberA1.mpn = [];
      this.manufacturePartNumberA1.manufacture_type = [];
      this.manufacturePartNumberA1.manufacture_note = [];

      this.equipmentQuantityInstallA1.equipment_code = [];
      this.equipmentQuantityInstallA1.qty_installed = [];
      this.equipmentQuantityInstallA1.drawing_number = [];

      this.attachmentDocumentA1.file_attachment_name = [];

      //this.$refs.formA1.reset();
      this.form.a1.show = true;
      this.form.a1.disabled = false;
      this.form.a1.type = "add";
    },

    addMpnA1() {
      if (this.manufacturePartNumberA1.manufacture_code.length == 0) {
        this.manufacturePartNumberA1.manufacture_code.push("");
        this.manufacturePartNumberA1.mpn.push("");
        this.manufacturePartNumberA1.manufacture_type.push("");
        this.manufacturePartNumberA1.manufacture_note.push("");
      } else if (this.manufacturePartNumberA1.manufacture_code.length > 0) {
        if (
          this.manufacturePartNumberA1.manufacture_code[
            this.manufacturePartNumberA1.manufacture_code.length - 1
          ] != "" &&
          this.manufacturePartNumberA1.mpn[
            this.manufacturePartNumberA1.mpn.length - 1
          ] != "" &&
          this.manufacturePartNumberA1.manufacture_type[
            this.manufacturePartNumberA1.manufacture_type.length - 1
          ] != "" &&
          this.manufacturePartNumberA1.manufacture_note[
            this.manufacturePartNumberA1.manufacture_note.length - 1
          ] != ""
        ) {
          this.manufacturePartNumberA1.manufacture_code.push("");
          this.manufacturePartNumberA1.mpn.push("");
          this.manufacturePartNumberA1.manufacture_type.push("");
          this.manufacturePartNumberA1.manufacture_note.push("");
        } else {
          this.$notify({
            message: this.tt("please_complete_data", { title: this.tt("mpn") }),
            type: "danger"
          });
        }
      }
    },
    removeMpnA1(key) {
      this.manufacturePartNumberA1.manufacture_code.splice(key, 1);
      this.manufacturePartNumberA1.mpn.splice(key, 1);
      this.manufacturePartNumberA1.manufacture_type.splice(key, 1);
      this.manufacturePartNumberA1.manufacture_note.splice(key, 1);
    },

    addMpn() {
      this.manufacturePartNumber.manufacture_code.push("");
      this.manufacturePartNumber.mpn.push("");
      this.manufacturePartNumber.manufacture_type.push("");
      this.manufacturePartNumber.manufacture_note.push("");
    },
    removeMpn(key) {
      this.manufacturePartNumber.manufacture_code.splice(key, 1);
      this.manufacturePartNumber.mpn.splice(key, 1);
      this.manufacturePartNumber.manufacture_type.splice(key, 1);
      this.manufacturePartNumber.manufacture_note.splice(key, 1);
    },
    addEqi() {
      this.equipmentQuantityInstall.equipment_code.push("");
      this.equipmentQuantityInstall.qty_installed.push("");
      this.equipmentQuantityInstall.drawing_number.push("");
    },
    removeEqi(key) {
      this.equipmentQuantityInstall.equipment_code.splice(key, 1);
      this.equipmentQuantityInstall.qty_installed.splice(key, 1);
      this.equipmentQuantityInstall.drawing_number.splice(key, 1);
    },
    addEqiA1() {
      if (this.equipmentQuantityInstallA1.equipment_code.length == 0) {
        this.equipmentQuantityInstallA1.equipment_code.push("");
        this.equipmentQuantityInstallA1.qty_installed.push("");
        this.equipmentQuantityInstallA1.drawing_number.push("");
      } else if (this.equipmentQuantityInstallA1.equipment_code.length > 0) {
        if (
          this.equipmentQuantityInstallA1.equipment_code[
            this.equipmentQuantityInstallA1.equipment_code.length - 1
          ] != "" &&
          this.equipmentQuantityInstallA1.qty_installed[
            this.equipmentQuantityInstallA1.qty_installed.length - 1
          ] != "" &&
          this.equipmentQuantityInstallA1.drawing_number[
            this.equipmentQuantityInstallA1.drawing_number.length - 1
          ] != ""
        ) {
          this.equipmentQuantityInstallA1.equipment_code.push("");
          this.equipmentQuantityInstallA1.qty_installed.push("");
          this.equipmentQuantityInstallA1.drawing_number.push("");
        } else {
          this.$notify({
            message: this.tt("please_complete_data", {
              title: this.tt("equipment_&_quantity_install")
            }),
            type: "danger"
          });
        }
      }
    },
    removeEqiA1(key) {
      this.equipmentQuantityInstallA1.equipment_code.splice(key, 1);
      this.equipmentQuantityInstallA1.qty_installed.splice(key, 1);
      this.equipmentQuantityInstallA1.drawing_number.splice(key, 1);
    },

    addAdA1() {
      if (this.attachmentDocumentA1.file_attachment_name.length == 0) {
        this.attachmentDocumentA1.file_attachment_name.push("");
      } else if (this.attachmentDocumentA1.file_attachment_name.length > 0) {
        if (
          this.attachmentDocumentA1.file_attachment_name[
            this.attachmentDocumentA1.file_attachment_name.length - 1
          ] == ""
        ) {
          this.$notify({
            message: this.tt("please_complete_data", {
              title: this.tt("attachment_document")
            }),
            type: "danger"
          });
        } else {
          this.attachmentDocumentA1.file_attachment_name.push("");
        }
      }
    },
    removeAdA1(key) {
      this.attachmentDocumentA1.file_attachment_name.splice(key, 1);
    },

    addAd() {
      this.attachmentDocumentA1.file_attachment_name.push("");
    },
    removeAd(key) {
      this.attachmentDocumentA1.file_attachment_name.splice(key, 1);
    },

    addPl() {
      this.plantLevel.plant.push("");
    },
    removePl(key) {
      this.plantLevel.plant.splice(key, 1);
    },
    editMcrInformation() {
      this.formMcrInformation.add = false;
      this.formMcrInformation.show = true;
    },
    updateMcrInformation() {
      let context = this;
      // context.errorMessage = null;
      context.btnUpdate.onLoading = true;
      Api(
        context,
        draftList.updateMcrSubject(
          context.mcrInformation.mcr_code,
          context.mcrInformation.token,
          context.mcrInformationEdit
        )
      )
        .onSuccess(function(response) {
          context.mcrInformation.subject =
            context.mcrInformationEdit.new_subject;
          context.$notify({
            message: response.data.message,
            type: "success",
            verticalAlign: "bottom",
            horizontalAlign: "left"
          });
        })
        .onFinish(function() {
          context.btnUpdate.onLoading = false;
          context.formMcrInformation.show = false;
        })
        .call();
    },
    changeFileAttachmentNameA1(files) {
      this.attachmentDocumentA1.file_attachment_name[
        this.attachmentDocumentA1.file_attachment_name.length - 1
      ] = files[0];
    },
    saveA1(save) {
      let context = this;
      let formData = new FormData();

      if (
        context.inputDraftListA1.mtype_code != "" &&
        context.inputDraftListA1.item_type_code != "" &&
        context.inputDraftListA1.plant_code != "" &&
        context.inputDraftListA1.fab_non_fab != "" &&
        context.inputDraftListA1.uom != "" &&
        context.inputDraftListA1.bom_non_bom != "" &&
        context.inputDraftListA1.item_name != "" &&
        context.inputDraftListA1.specification != "" &&
        context.inputDraftListA1.critical_non_critical != "" &&
        context.attachmentDocumentA1.file_attachment_name.length > 0 &&
        context.attachmentDocumentA1.file_attachment_name[
          context.attachmentDocumentA1.file_attachment_name.length - 1
        ] != "" &&
        context.inputDraftListA1.note != ""
      ) {
        formData.append("mcr_code", context.mcr_code);
        formData.append("mtype_code", context.inputDraftListA1.mtype_code);
        formData.append(
          "item_type_code",
          context.inputDraftListA1.item_type_code
        );
        formData.append("plant_code", context.inputDraftListA1.plant_code);
        formData.append("fab_non_fab", context.inputDraftListA1.fab_non_fab);
        formData.append("uom_code", context.inputDraftListA1.uom);
        formData.append("bom_non_bom", context.inputDraftListA1.bom_non_bom);
        formData.append("item_name", context.inputDraftListA1.item_name);
        formData.append("specification", context.inputDraftListA1.specification);
        formData.append("critical_non_critical", context.inputDraftListA1.critical_non_critical);
        formData.append("note", context.inputDraftListA1.note);

        for (
          var i = 0;
          i < context.manufacturePartNumberA1.manufacture_code.length;
          i++
        ) {
          if (
            context.manufacturePartNumberA1.manufacture_code[i] != "" &&
            context.manufacturePartNumberA1.mpn[i] != "" &&
            context.manufacturePartNumberA1.manufacture_type[i] != "" &&
            context.manufacturePartNumberA1.manufacture_note[i] != ""
          ) {
            formData.append(
              "manufacture_code[" + i + "]",
              context.manufacturePartNumberA1.manufacture_code[i]
            );
            formData.append(
              "mpn[" + i + "]",
              context.manufacturePartNumberA1.mpn[i]
            );
            formData.append(
              "manufacture_type[" + i + "]",
              context.manufacturePartNumberA1.manufacture_type[i]
            );
            formData.append(
              "manufacture_note[" + i + "]",
              context.manufacturePartNumberA1.manufacture_note[i]
            );
          } else {
            context.$notify({
              message: this.tt("please_complete_data", {
                title: this.tt("mpn")
              }),
              type: "danger"
            });
            return false;
          }
        }

        for (
          var i = 0;
          i < context.equipmentQuantityInstallA1.equipment_code.length;
          i++
        ) {
          if (
            context.equipmentQuantityInstallA1.equipment_code[i] != "" &&
            context.equipmentQuantityInstallA1.qty_installed[i] != "" &&
            context.equipmentQuantityInstallA1.drawing_number[i] != ""
          ) {
            formData.append(
              "equipment_code[" + i + "]",
              context.equipmentQuantityInstallA1.equipment_code[i]
            );
            formData.append(
              "qty_installed[" + i + "]",
              context.equipmentQuantityInstallA1.qty_installed[i]
            );
            formData.append(
              "drawing_number[" + i + "]",
              context.equipmentQuantityInstallA1.drawing_number[i]
            );
          } else {
            context.$notify({
              message: this.tt("please_complete_data", {
                title: this.tt("equipment_&_quantity_install")
              }),
              type: "danger"
            });
            return false;
          }
        }

        let api = "";
        if (save == "add") {
          for (
            var i = 0;
            i < context.attachmentDocumentA1.file_attachment_name.length;
            i++
          ) {
            formData.append(
              "file_attachment_name[" + i + "]",
              context.attachmentDocumentA1.file_attachment_name[i]
            );
          }
          api = Api(context, draftList.createNewMcrItem(formData));
        } else {
          let index = 0;
          for (
            var i = 0;
            i < context.attachmentDocumentA1.file_attachment_name.length;
            i++
          ) {
            if (
              context.attachmentDocumentA1.file_attachment_name[i]
                .lastModified != null
            ) {
              formData.append(
                "file_attachment_name[" + index + "]",
                context.attachmentDocumentA1.file_attachment_name[i]
              );
              index++;
            }
          }
          api = Api(
            context,
            draftList.updateMcrItem(formData, context.inputDraftListA1.id)
          );
        }
        context.form.a1.btnSave.onLoading = true;
        api
          .onSuccess(function(response) {
            context.get();
            context.$notify({
              message: response.data.message,
              type: "success"
            });
            context.form.a1.show = false;
          })
          .onFinish(function() {
            context.form.a1.btnSave.onLoading = false;
          })
          .call();
      } else {
        context.$notify({
          message: this.tt("please_complete_data", {
            title: this.tt("basic_data_&_specification")
          }),
          type: "danger"
        });
      }
    },
    sendMcr(mcr_code) {
      this.btnSend.onLoading = true;
      let api = "";
      let context = this;
      api = Api(context, draftList.sendMcr(mcr_code));
        api.onSuccess(function(response) {
          context.$notify({
            message: response.data.message,
            type: "success"
          });
          context.$router.push('/material/draft-list');
        })
        .onFinish(function() {
          context.btnSend.onLoading = false;
        })
        .call();
    },
    toggleAccordion(show, type) {
      if (type == "bdsA1") {
        if (show == "") {
          this.accordion.a1.bds.show = "show";
        } else {
          this.accordion.a1.bds.show = "";
        }
      } else if (type == "mpnA1") {
        if (show == "") {
          this.accordion.a1.mpn.show = "show";
        } else {
          this.accordion.a1.mpn.show = "";
        }
      } else if (type == "eqiA1") {
        if (show == "") {
          this.accordion.a1.eqi.show = "show";
        } else {
          this.accordion.a1.eqi.show = "";
        }
      } else if (type == "adA1") {
        if (show == "") {
          this.accordion.a1.ad.show = "show";
        } else {
          this.accordion.a1.ad.show = "";
        }
      }
    },

    // AUTOCOMPLETE

    querySearchPlant(queryString, cb) {
      var links = this.plantCode;
      var results = queryString ? links.filter(this.createFilter(queryString)) : links;
      cb(results);
    },
    selectPlant(item) {
        this.inputDraftListA1.plant_code = item.code
    },
    querySearchUom(queryString, cb) {
      var links = this.unitOfMeasure;
      var results = queryString ? links.filter(this.createFilter(queryString)) : links;
      cb(results);
    },
    selectUom(item) {
        this.inputDraftListA1.uom = item.code
    },
    querySearchManufacture(queryString, cb) {
      if (queryString.length > 2) {
        var links = this.manufacture;
        var results = queryString ? links.filter(this.createFilter(queryString)) : links;
        cb(results);
      }
    },
    selectManufacture(item) {
        this.manufacturePartNumberA1.manufacture_code[this.manufactureKey] = item.code
    },
    querySearchEquipment(queryString, cb) {
      if (queryString.length > 2) {
        var links = this.equipmentCode;
        var results = queryString ? links.filter(this.createFilter(queryString)) : links;
        cb(results);
      }
    },
    selectEquipment(item) {
        this.equipmentQuantityInstallA1.equipment_code[this.equipmentKey] = item.code
    },
    createFilter(queryString) {
        return (link) => {
            return (link.value.toLowerCase().indexOf(queryString.toLowerCase()) > -1);
        };
    },
    selectedManufactureKey(key) {
      this.manufactureKey = key;
    },
    selectedEquipmentKey(key) {
      this.equipmentKey = key;
    },
    tracking() {
        this.formTracking.show = false;
        this.formTracking.show = true;
    },
  }
};
</script>
<style></style>
